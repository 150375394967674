import React, { useEffect, useState } from "react";
import Link from "next/link";
import tw, { styled } from "twin.macro";

export default function Footer() {

    let year = new Date().getFullYear();
    const [eggId, setEggId] = useState(0);

    // useEffect(() => {

    //     let _egg = localStorage.getItem('_id');
    //     if (_egg !== null) {
    //         setEggId(_egg);
    //     }

    // }, []);

    return (
        <FooterContainer>
            <Container>
                <LinksContainer>
                    <Link href="/halaal" legacyBehavior>
                        <TextFooterLink>
                            Halaal
                        </TextFooterLink>
                    </Link>
                    <TextFooterSep>
                        |
                    </TextFooterSep>
                    <Link href="/terms-and-conditions" legacyBehavior>
                        <TextFooterLink>
                            Terms & Conditions
                        </TextFooterLink>
                    </Link>
                    <TextFooterSep>
                        |
                    </TextFooterSep>
                    <Link href="/privacy-policy" legacyBehavior>
                        <TextFooterLink>
                            Privacy Policy
                        </TextFooterLink>
                    </Link>
                    {/* <TextFooterSep>
                        |
                    </TextFooterSep>
                    <Link href="/contact-us">
                        <TextFooterLink>
                            Contact Us
                        </TextFooterLink>
                    </Link> */}
                </LinksContainer>
            </Container>
            <TextCopyright>
                &copy;{year} DEL FORNO {process.env.NEXT_PUBLIC_VERSION}.{eggId} 07/08/2024
            </TextCopyright>
        </FooterContainer>
    );
}

const Container = styled.div`
    ${tw`flex flex-col items-center pt-1`}
`;

const TextFooterSep = styled.div`
    ${tw`pt-2 px-2 text-xs text-grey-dark text-center`}
`;

const TextFooterLink = styled.div`
    ${tw`pt-2 text-xs text-grey text-center cursor-pointer`}
`;

const LinksContainer = styled.div`
    ${tw`max-w-sm pl-2 pr-2 flex flex-row place-content-between`}
`;

const TextCopyright = styled.div`
    ${tw`pt-[4px] tracking-wide text-xs text-white text-center`}
`;

const FooterContainer = styled.div`
    ${tw`w-full bg-black text-center text-white h-[60px]`}
`;